<template>
  <form @submit.prevent="save">
    <div class="d-flex">
      <div class="flex-grow-1 me-3">
        <div class="form-floating">
          <input
            v-model.trim="session.name"
            :disabled="isBusy"
            ref="sessionName"
            type="text"
            class="form-control"
            id="session-name"
            placeholder="Insert session name..."
          >
          <label for="session-name">Name</label>
        </div>
        <div v-if="programId === null" class="mt-3 form-floating">
          <select
            v-model="session.status"
            :disabled="isBusy"
            ref="sessionStatus"
            class="form-select"
            id="session-status"
          >
            <option disabled>Select a status</option>
            <option
              v-for="(status, statusIndex) in statusList"
              :key="`key-${statusIndex}`"
              :value="status.value"
            >{{ status.name }}</option>
          </select>
          <label for="session-status">Status</label>
          <div class="form-text">{{ sessionStatusReadable }}</div>
        </div>
      </div>
      <div>
        <SubmitButton
          :text="'Save'"
          :isLoading="isBusy"
          :disabled="isBusy"
          class="btn btn-primary h-100 submit"
        ></SubmitButton>
      </div>
    </div>
  </form>
</template>

<script>
import { sessionStatusList } from '@/core/setting';

export default {
  name: 'SessionEditorOverview',
  components: {
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    programId: {
      type: String,
      default: null,
    },
  },
  watch: {
    session() {
      this.isBusy = false;
    },
  },
  computed: {
    statusList() {
      return sessionStatusList;
    },
    sessionStatusReadable() {
      const { session, statusList } = this;
      const status = statusList.find((s) => s.value === session.status);
      if (status) return status.description;
      return null;
    },
  },
  methods: {
    save() {
      const { name, status } = this.session;
      const payload = { name, status };
      this.isBusy = true;
      this.$emit('sessionEditorOverviewUpdate', payload);
    },
  },
  data() {
    return {
      isBusy: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.submit {
  width: 6rem;
}
</style>
