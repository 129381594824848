const sessionStatusList = [
  {
    name: 'Draft',
    value: 'draft',
    description: 'The session is still being prepared. Won\'t appear in public.',
  },
  {
    name: 'Published',
    value: 'published',
    description: 'The session is live.',
  },
];

const chartColors = {
  carb: '#005f73',
  carbDark: '#005f73',
  carbAlt: '#005f73',
  carbAltDark: '#005f73',
  protein: '#7b2cbf',
  proteinDark: '#7b2cbf',
  proteinAlt: '#7b2cbf',
  proteinAltDark: '#7b2cbf',
  fat: '#fb5607',
  fatDark: '#fb5607)',
  fatAlt: '#fb5607',
  fatAltDark: '#fb5607',
};

const chartTooltipStyles = {
  caretSize: 0,
  cornerRadius: 3,
  displayColors: false,
};

const chartBarItemStyles = {
  fill: true,
  borderWidth: 0,
  hoverBorderWidth: 0,
};

const chartLineItemStyles = {
  backgroundColor: 'rgba(0, 123, 255, .1)',
  borderCapStyle: 'round',
  borderColor: '#0466c8',
  borderWidth: 2,
  fill: false,
  pointStyle: 'circle',
  pointRadius: 1,
};

export {
  sessionStatusList,
  chartColors,
  chartTooltipStyles,
  chartBarItemStyles,
  chartLineItemStyles,
};
